<template>
  <div
    style="background-color: #f6f0e4"
    class="relative text-xs flex flex-col text-left w-full"
  >
    <div
      class="
        glass-overlay
        flex
        items-center
        justify-center
        font-bold
        text-lg
        absolute
        top-0
        bottom-0
        right-0
        left-0
        bg-black
        z-50
      "
    >
      <h2
        class="rounded-xl"
        style="background: rgba(255, 255, 255, 0.4); padding: 2rem"
      >
        Please select a ticket to view details.
      </h2>
    </div>

    <div class="w-full py-4 shadow-md flex items-center">
      <h1 class="text-black text-xl px-6">Are Turbofan ovens available?</h1>
      <span class="text-gray-400 uppercase"> product availability</span>
    </div>
    <!-- Messages -->
    <div class="overflow-auto flex flex-col-reverse mb-3 slim-scrollbar">
      <div class="chat-message mt-2">
        <div class="flex items-end justify-end">
          <div
            class="
              flex flex-col
              space-y-2
              text-xs
              max-w-xs
              mx-2
              order-1
              items-end
            "
          >
            <div>
              <span
                class="
                  px-4
                  py-2
                  rounded-lg
                  inline-block
                  rounded-br-none
                  bg-gray-700
                  text-white
                "
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
                consequatur odio quibusdam incidunt corporis expedita rerum,
                velit temporibus reiciendis tempora!
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-message">
        <div class="flex items-end justify-end">
          <div
            class="
              flex flex-col
              space-y-2
              text-xs
              max-w-xs
              mx-2
              order-1
              items-end
            "
          >
            <div>
              <span
                class="
                  px-4
                  py-2
                  rounded-lg
                  inline-block
                  rounded-br-none
                  bg-gray-700
                  text-white
                "
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo
                consequatur odio quibusdam incidunt corporis expedita rerum,
                velit temporibus reiciendis tempora!
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="chat-message">
        <div class="flex items-end">
          <div
            class="
              flex flex-col
              space-y-2
              text-xs
              max-w-xs
              mx-2
              order-2
              items-start
            "
          >
            <div>
              <span
                class="
                  px-4
                  py-2
                  rounded-lg
                  inline-block
                  bg-gray-300
                  text-gray-600
                "
                >Command was run with root privileges. I'm sure about
                that.</span
              >
            </div>
            <div>
              <span
                class="
                  px-4
                  py-2
                  rounded-lg
                  inline-block
                  bg-gray-300
                  text-gray-600
                "
                >Your error message says permission denied, npm global installs
                must be given root privileges.</span
              >
            </div>
            <div>
              <span
                class="
                  px-4
                  py-2
                  rounded-lg
                  inline-block
                  bg-gray-300
                  text-gray-600
                "
                >FYI Your error message says permission denied, npm global
                installs must be given root privileges.</span
              >
            </div>
            <div>
              <span
                class="
                  px-4
                  py-2
                  rounded-lg
                  inline-block
                  rounded-bl-none
                  bg-gray-300
                  text-gray-600
                "
              >
                Check the line above (it ends with a # so, I'm running it as
                root )
                <pre># npm install -g @vue/devtools</pre>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Messages -->
    <section class="mt-auto border rounded-xl bg-gray-50 mb-3 m-2">
      <textarea
        class="w-full bg-gray-50 p-2 rounded-xl"
        placeholder="Type your reply here..."
        rows="3"
      ></textarea>
      <div class="flex items-center justify-between p-2">
        <button class="h-6 w-6 text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
            />
          </svg>
        </button>

        <button
          type="button"
          class="
            inline-flex
            items-center
            justify-center
            rounded-full
            h-12
            w-12
            transition
            duration-500
            ease-in-out
            text-white
            bg-yellow-500
            hover:bg-yellow-400
            focus:outline-none
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="h-6 w-6 transform rotate-90"
          >
            <path
              d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
            ></path>
          </svg>
        </button>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
.chat-message:last-child {
  margin-top: 1rem;
}
.glass-overlay {
  background: rgba(246, 240, 228, 0.494);
  backdrop-filter: blur(10.3px);
  -webkit-backdrop-filter: blur(10.3px);
  border: 1px solid rgba(255, 252, 252, 0.44);
}
</style>