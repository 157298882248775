<template>
  <div style="margin-top: 56px; height: calc(100vh - 56px)">
    <div
      class="w-full sm:w-full md:flex lg:flex xl:flex"
      style="height: calc(100vh - 56px)"
    >
      <!-- Support Chats -->
      <div
        class="
          slim-scrollbar
          overflow-auto
          w-full
          sm:w-full
          md:w-2/3
          lg:w-2/3
          xl:w-2/3
          bg-white
          text-left
        "
      >
        <div v-if="tickets && tickets.length">
          <div
            v-for="(ticket, i) in tickets"
            :key="ticket._id"
            @click="(selectedTicketIndex = i), readTicketMessages(ticket._id)"
            :style="{ background: selectedTicketIndex === i ? '#f6f0e4' : '' }"
            :class="{
              'bg-yellow-200 font-bold': ticket.unreadMessagesAdmin,
            }"
            class="
              cursor-pointer
              py-4
              p-6
              text-black
              bg-white
              hover:bg-gray-50
              border border-gray-150 border-l-0 border-r-0
            "
          >
            <span style="color: #d4b376" class="font-bold mr-2 text-xs">
              #{{ ticket.ticketId }}
            </span>
            <span class="uppercase text-gray-400 text-xs">
              {{ ticket.category }}
            </span>
            <div class="py-1 flex items-center">
              <h1 class="text-md font-semibold text-black capitalize">
                {{ ticket.subject }}
              </h1>
              <button
                :class="{
                  'bg-yellow-50 text-yellow-400': ticket.status === 'open',
                  'bg-green-50 text-green-400': ticket.status === 'closed',
                }"
                class="ml-2 font-semibold px-3 py-0 rounded capitalize"
              >
                {{ ticket.status }}
              </button>
            </div>
            <div class="py-1 text-xs">
              {{ $moment(ticket.date).format("DD MMMM YYYY") }}
            </div>
          </div>
        </div>
      </div>
      <!-- / Support Chats -->
      <div
        v-if="selectedTicket"
        style="background-color: #f6f0e4"
        class="text-xs flex flex-col text-left w-full"
      >
        <div class="w-full flex custom-shadow items-center">
          <h1 class="text-black text-xl px-6 capitalize">
            {{ selectedTicket.subject }}
          </h1>
          <span class="text-gray-400 uppercase">
            {{ selectedTicket.category }}</span
          >
          <button
            v-if="selectedTicket.status === 'open'"
            @click="closeTicket(selectedTicket._id)"
            class="font-bold px-4 py-4 ml-auto text-white bg-black"
          >
            Close Ticket
          </button>
          <span
            v-if="selectedTicket.status === 'closed'"
            @click="closeTicket(selectedTicket._id)"
            class="font-bold px-4 py-4 ml-auto text-green-400"
          >
            Reply to reopen ticket
          </span>
        </div>
        <!-- Messages -->
        <div class="overflow-auto flex flex-col-reverse mb-3 slim-scrollbar">
          <div
            v-for="(message, i) in selectedTicket.messages"
            :key="i"
            class="chat-message"
          >
            <div
              class="flex items-end"
              :class="{
                'items-end': !message.adminReply,
                'items-end justify-end': message.adminReply,
              }"
            >
              <div class="flex flex-col text-xs max-w-xs mx-2">
                <div class="text-xs p-1 font-bold">
                  {{ message.sender }}
                </div>
                <div>
                  <span
                    :class="{
                      'bg-gray-300 text-gray-600 rounded-bl-none':
                        !message.adminReply,
                      'bg-gray-700 text-white rounded-br-none':
                        message.adminReply,
                    }"
                    class="px-4 py-2 rounded-lg inline-block"
                    >{{ message.message }}</span
                  >
                </div>
                <a
                  :href="`${api_host}/uploads/${file.file}`"
                  target="_blank"
                  class="
                    text-xs
                    hover:bg-gray-100
                    py-2
                    px-4
                    flex
                    gap-2
                    rounded
                    bg-white
                  "
                  v-for="(file, i) in message.attachments"
                  :key="i"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"
                    ></path>
                  </svg>
                  {{ file.name }}
                </a>
                <div class="text-xs p-1">
                  {{ $moment(message.date).format("DD/MM/YY HH:mm") }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- / Messages -->
        <section class="mt-auto border rounded-xl bg-gray-50 mb-3 m-2">
          <textarea
            v-model="newMessage.message"
            class="w-full bg-gray-50 p-2 rounded-xl"
            placeholder="Type your reply here..."
            rows="3"
          ></textarea>
          <div class="flex items-center p-2">
            <label
              for="attachmentInput"
              class="cursor-pointer h-6 w-6 text-gray-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                />
              </svg>
            </label>
            <button
              @click="newMessage.files = []"
              v-show="newMessage.files.length"
              class="ml-2 bg-yellow-700 py-2 px-4 rounded text-white"
            >
              Clear attachments
            </button>
            <input
              multiple
              @change="attachmentsSelected"
              type="file"
              id="attachmentInput"
              name="attachmentInput"
              class="hidden"
            />
            <button
              type="button"
              @click.prevent="
                sendTicketReply({ ...newMessage, id: selectedTicket._id }),
                  (newMessage = { message: '', files: '' })
              "
              :disabled="newMessage.message === ''"
              :class="{
                'cursor-not-allowed bg-gray-200': newMessage.message === '',
                'bg-yellow-500 hover:bg-yellow-400': newMessage.message !== '',
              }"
              class="
                ml-auto
                inline-flex
                items-center
                justify-center
                rounded-full
                h-12
                w-12
                transition
                duration-500
                ease-in-out
                text-white
                focus:outline-none
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="h-6 w-6 transform rotate-90"
              >
                <path
                  d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
                ></path>
              </svg>
            </button>
          </div>
          <div
            class="
              inline-block
              shadow
              ml-2
              rounded
              mb-2
              bg-white
              rounded
              px-4
              py-2
            "
            v-for="(file, i) in newMessage.files"
            :key="i"
          >
            {{ file.name }}
          </div>
        </section>
      </div>
      <ChatPlaceHolder v-else></ChatPlaceHolder>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ChatPlaceHolder from "@/components/modules/Support/ChatPlaceHolder";
export default {
  name: "Support",
  data() {
    return {
      selectedTicketIndex: null,
      api_host: process.env.VUE_APP_API_HOST,

      newMessage: {
        message: "",
        files: "",
      },
    };
  },
  watch: {
    selectedTicketIndex() {
      this.newMessage = {
        message: "",
        files: "",
      };
    },
  },
  methods: {
    ...mapActions([
      "getSupportTickets",
      "readTicketMessages",
      "closeTicket",
      "sendTicketReply",
    ]),
    attachmentsSelected(e) {
      this.newMessage.files = [...e.target.files];
      e.target.value = null;
    },
  },
  computed: {
    ...mapGetters({ tickets: "supportTickets" }),
    selectedTicket() {
      if (
        this.selectedTicketIndex >= 0 &&
        this.tickets &&
        this.tickets.length
      ) {
        return this.tickets[this.selectedTicketIndex];
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.getSupportTickets();
  },
  components: {
    ChatPlaceHolder,
  },
};
</script>


<style scoped>
.chat-message:last-child {
  margin-top: 1rem;
}
.custom-shadow {
  box-shadow: 2px 4px 8px -3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 2px 4px 8px -3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 4px 8px -3px rgba(0, 0, 0, 0.2);
}
</style>


